import React from 'react';

export default class Footer extends React.Component {
    render() {
        return (
            <div className="footer text-center text-xl-left py-4 py-sm-5">
                <div className="container justify-content-between">
                    <div className="row align-items-end">
                        <div className="col-12 col-xl-4">
                            <p className='mb-0'><strong>Culliton Channel Consulting</strong><br />
                                <a href="mailto: Info@cullitonchannelconsulting.com">Info@cullitonchannelconsulting.com</a>
                                <br />
                                <a href="tel:904-273-0315">(904) 273-0315</a>
                                <br />
                                513 South Sea Lake Lane
                                <br />
                                Ponte Vedra Beach, FL 32082
                            </p>
                        </div>
                        <div className="col">
                            <p id="copy" className='text-center text-xl-right mb-0'>&copy; Copyright {(new Date().getFullYear())}, Culliton Channel Consulting. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
