import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import $ from "jquery";

import culliton_channel_consulting_logo from './../images/Culliton_Channel_Consulting_Logo.png';
import ctaImg from './../images/CTA_Member_treatment.png';
export default class Header extends React.Component {
    // componentDidUpdate() {
    //     $('.nav-toggle').toggleClass('active');
    //     $('.nav-items').slideToggle();
    // }
    // componentDidMount() {
    //     $('.nav-toggle').click(function () {
    //         $('.nav-toggle').toggleClass('active');
    //         $('.nav-items').slideToggle();
    //     });
    // }
    render() {
        return (
            <>
                <nav className="navbar navbar-expand-lg navbar-light position-fixed bg-light p-3">
                    <div className="container">
                        <Link to={"/"}>
                            <img id="logo" alt="Culliton Channel Consulting logo" src={culliton_channel_consulting_logo} />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <div className="nav-toggle">
                                <i id="bars" className="fa fa-bars"></i>
                            </div>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end mt-3 mt-lg-0 ml-3" id="navbarNav">
                            <ul className="navbar-nav d-block d-lg-flex p-0">
                                <li className="nav-item">
                                    <NavLink className="nav-link" exact to={"/"}>
                                        Home
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to={"/services"}>
                                        Services
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to={"clients"}>
                                        Clients
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to={"testimonials"}>
                                        Testimonials
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to={"about-us"}>
                                        About Us
                                    </NavLink>
                                </li>
                                <div id="cta-container" className='d-none d-lg-block'>
                                    <img id="cta-image" alt="Consumer Technology Association Member" src={ctaImg} />
                                </div>
                            </ul>
                        </div>
                    </div>
                </nav>
            </>

        );
    }
}